<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getSubscriptions } from '@/api/Subscriptions.js'
  import { Subscription } from "@/models/Subscription";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Assinaturas",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "Assinaturas",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Assinaturas",
            active: true,
          },
        ],
        list:[],
        params: {
          page: 1,
          limit: 50
        },
        ui:{
          pagination:{
            current_page: 1,
            last_page: 1,
            per_page: 20,
            total: 0
          }
        }
      };
    },
    computed: {
      hasItems: function () {
        return this.list.length > 0
      },
      partner: function (){
        return JSON.parse(localStorage.getItem('partner'));
      }
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        getSubscriptions(this.params).then(response => {
          vm.params.page = response.current_page
          vm.params.limit = response.per_page

          vm.ui.pagination.current_page = response.current_page
          vm.ui.pagination.last_page = response.last_page
          vm.ui.pagination.per_page = parseInt(response.per_page)
          vm.ui.pagination.total = response.total

          vm.list = response.data.map(item => {
            return new Subscription(item)
          })
        })
      },
    },
    watch: {
      "ui.pagination.current_page": function (value) {
        this.params.page = value
        this.fetchData()
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Empresa</th>
                  <th>Plano</th>
                  <th class="text-center" v-if="partner.type === 'reseller'">Demo</th>
                  <th>Vencimento</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!hasItems">
                  <td colspan="100%" class="text-center">
                    <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
                  </td>
                </tr>
                <tr v-else v-for="item in list" v-bind:key="item.id">
                  <th scope="row">
                    {{ item.id }}
                  </th>
                  <td>
                    <router-link
                      tag="a"
                      :to="`/companies/${item.company.id}`"
                      class="text-primary"
                    >
                      {{ item.company.name }}
                    </router-link>
                  <td>{{ item.package.name }}</td>
                  <td class="text-center" v-if="partner.type === 'reseller'">
                    <span v-if="item.demo" class="badge badge-danger">Sim</span>
                    <span v-else class="badge badge-warning">Não</span>
                  </td>
                  <td class="min">{{ $d(item.period_end, 'short') }}</td>
                  <td class="min">
                    <button
                      type="button"
                      class="btn btn-light btn-sm btn-rounded"
                      @click="$router.push(`/subscriptions/${item.id}`)"
                    >
                      <i class="mdi mdi-eye" />
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
  
              <b-pagination
                :total-rows="ui.pagination.total"
                :per-page="ui.pagination.per_page"
                v-model="ui.pagination.current_page"
                prev-text="Anterior"
                next-text="Próxima"
                align="center"
                class="mt-3 mb-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
